<template>
  <div class="contents register wedding scheduleBox">
    <div class="title flexB">
      <h2>스케줄 관리</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            구분
            <span>*</span>
          </p>
          <div class="labelBox">
            <label>
              <input
                type="radio"
                value="client"
                v-model="scheduleType"
                :disabled="editMode"
              />
              고객 스케줄
            </label>
            <label>
              <input
                type="radio"
                value="planner"
                v-model="scheduleType"
                :disabled="editMode"
              />
              일반 스케줄
            </label>
          </div>
        </div>
        <div class="flexL" v-if="scheduleType == 'client'">
          <p class="bold">
            고객명
            <span>*</span>
          </p>
          <div class="plannerInput">
            <p @click="handleModal('user')">{{ user.username }}</p>
          </div>
        </div>
        <div class="flexL" v-if="this.adminType == 'SUPER'">
          <p class="bold">
            담당자
            <span>*</span>
          </p>
          <div class="plannerInput">
            <p @click="handleModal('planner')">
              {{ planner.plannerName }}
              <span v-if="planner.plannerType">{{
                planner.plannerType == "DIRECTOR" ? "디렉터" : "플래너"
              }}</span>
            </p>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">
            스케줄 항목
            <span>*</span>
          </p>
          <el-select
            v-model="category"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="방문 상담" label="방문 상담" value="방문 상담">
            </el-option>
            <el-option key="출장 상담" label="출장 상담" value="출장 상담">
            </el-option>
            <el-option key="웨딩 촬영" label="웨딩 촬영" value="웨딩 촬영">
            </el-option>
            <el-option
              key="드레스 투어"
              label="드레스 투어"
              value="드레스 투어"
            >
            </el-option>
            <el-option
              key="드레스 가봉"
              label="드레스 가봉"
              value="드레스 가봉"
            >
            </el-option>
            <el-option
              key="웨딩홀 투어"
              label="웨딩홀 투어"
              value="웨딩홀 투어"
            >
            </el-option>
            <el-option key="혼수 동행" label="혼수 동행" value="혼수 동행">
            </el-option>
            <el-option key="TM" label="TM" value="TM"> </el-option>
            <el-option key="미팅" label="미팅" value="미팅"> </el-option>
            <el-option
              key="리허설 메이크업"
              label="리허설 메이크업"
              value="리허설 메이크업"
            >
            </el-option>
            <el-option
              key="포토 메이크업"
              label="포토 메이크업"
              value="포토 메이크업"
            >
            </el-option>
            <el-option
              key="본식 메이크업"
              label="본식 메이크업"
              value="본식 메이크업"
            >
            </el-option>
            <el-option
              key="본식 영상 촬영"
              label="본식 영상 촬영"
              value="본식 영상 촬영"
            >
            </el-option>
            <el-option
              key="메이킹 영상 촬영"
              label="메이킹 영상 촬영"
              value="메이킹 영상 촬영"
            >
            </el-option>
            <el-option key="웨딩 본식" label="웨딩 본식" value="웨딩 본식">
            </el-option>
          </el-select>
        </div>

        <div class="flexL">
          <p class="bold">
            스케줄 날짜
            <span>*</span>
          </p>
          <el-date-picker
            v-model="date"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            placeholder="날짜와 시간을 선택해 주세요."
          >
          </el-date-picker>
        </div>
        <div class="flexL">
          <p class="bold">스케줄 체크</p>
          <div class="labelBox">
            <label>
              <input type="radio" value="동행" v-model="followup" />
              동행(외출)
            </label>
            <label>
              <input type="radio" value="완료" v-model="followup" />
              완료(방문)
            </label>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            스케줄명
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL">
          <p class="bold">스케줄 내용</p>
          <textarea v-model="content" />
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/schedulemanage">목록</router-link>
        <button class="basic" @click="submit" :disabled="inactive == true">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
    <div class="box one" v-if="user">
      <div class="tableList">
        <h2 class="bold">{{ user.username }}님 스케줄 리스트</h2>
        <!-- <table v-if="portfolioList.length > 0"> -->
        <table v-if="scheduleList.length > 0">
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 10%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 40%" />
          </colgroup>
          <tr>
            <th>날짜</th>
            <th>담당자</th>
            <th>항목</th>
            <th>스케줄명</th>
            <th>스케줄 내용</th>
          </tr>
          <tr v-for="(data, i) in scheduleList" :key="i">
            <td>{{ moment(data.date).format("YYYY.MM.DD HH:mm") }}</td>
            <td>{{ data.planner.plannerName }}</td>
            <td>{{ data.category }}</td>
            <td>{{ data.title }}</td>
            <td>{{ data.content }}</td>
          </tr>
        </table>

        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>

    <div class="modal" v-if="modalVisible">
      <div class="modal_bg" @click="modalReset"></div>
      <div class="modal_body">
        <div class="modal_header">
          <p>검색</p>
          <button @click="modalReset">
            <span class="material-icons-round"> clear </span>
          </button>
        </div>
        <div class="modal_content">
          <div class="searchWrap">
            <input
              type="text"
              placeholder="이름을 입력해 주세요"
              v-model="searchWord"
              @keyup.enter="search"
            />
            <button @click="search">
              <span class="material-icons-round"> search </span>
            </button>
          </div>
          <div class="tableBox">
            <table v-if="searchResults.length > 0">
              <tr>
                <th>이름</th>
              </tr>
              <tr
                v-for="(data, i) in searchResults"
                :key="i"
                @click="userSelect(data)"
              >
                <td v-if="userType == 'user'">{{ data.username }}</td>
                <td v-else>
                  {{ data.plannerName }}
                  {{
                    data.plannerType == "DIRECTOR"
                      ? "디렉터"
                      : data.plannerType == "PLANNER"
                      ? "플래너"
                      : data.plannerType == "TOURPLANNER"
                      ? "투어플래너"
                      : ""
                  }}
                </td>
              </tr>
            </table>
            <div
              class="nodata"
              v-else-if="searchResults.length == 0 && searchWord != ''"
            >
              <p>검색결과가 없습니다.</p>
            </div>
            <div class="pagination" v-if="searchResults.length > 0">
              <el-pagination
                layout="prev, pager, next"
                :total="modalTotal"
                :page-size="10"
                :pager-count="5"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  createSchedule,
  fetchScheduleDetail,
  updateSchedule,
  removeSchedule,
  fetchUserList,
  fetchPlannerList,
  fetchScheduleList,
} from "@/api/admin";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      scheduleId: "",
      title: "",
      category: "",
      content: "",
      date: "",
      followup: "",
      scheduleType: "",
      currentPage: 1,
      total: 0,

      modalVisible: false,
      inactive: false,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      planner: "",
      userType: "",
      scheduleList: [],

      adminType: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5-2");
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.scheduleId = this.$route.query.id;
      this.getScheduleDetail();
    }
    if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
      this.planner = localStorage.getItem("plannerId");
    }
  },
  methods: {
    async getScheduleDetail() {
      await fetchScheduleDetail(this.scheduleId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.content = res.data.data.content;
          this.date = res.data.data.date;
          this.scheduleType = res.data.data.scheduleType;
          this.followup = res.data.data.followup;
          this.planner = res.data.data.planner;
          this.user = res.data.data.user;
        } else {
          this.openAlert(res.data.message);
        }
      });
      if (this.scheduleType == "client") {
        await this.getScheduleList();
      }
    },
    getUserList() {
      let params = {
        page: this.modalPage,
        inputMode: "username",
        searchWord: this.searchWord,
        userType: this.userType,
      };
      if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
        params.plannerId = this.planner;
      }
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.users;
          this.modalTotal = res.data.total;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getPlannerList() {
      let params = {
        page: this.modalPage,
        inputMode: "plannerName",
        searchWord: this.searchWord,
        userType: this.userType,
      };
      fetchPlannerList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.data;
          this.modalTotal = res.data.total;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    handleCurrentChange(val) {
      if (this.modalVisible == true) {
        this.modalPage = val;
        if (this.userType === "user") {
          this.getUserList();
        } else if (this.userType === "planner") {
          this.getPlannerList();
        }
      } else {
        this.currentPage = val;
      }
    },
    userSelect(data) {
      if (this.userType == "user") {
        this.user = data;
      } else {
        this.planner = data;
      }
      this.getScheduleList();
      this.modalReset();
    },
    search() {
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      // userType에 따라 각각의 리스트를 불러옴
      if (this.userType === "user") {
        this.getUserList();
      } else if (this.userType === "planner") {
        this.getPlannerList();
      }
    },
    modalReset() {
      this.searchWord = "";
      this.userType = "";
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.modalVisible = !this.modalVisible;
    },
    handleModal(type) {
      this.modalVisible = !this.modalVisible;
      this.userType = type;
      if (type == "user") {
        this.getUserList();
      } else {
        this.getPlannerList();
      }
    },

    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeSchedule(this.scheduleId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "스케쥴 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/schedulemanage");
          }
        });
      });
    },

    submit() {
      if (this.scheduleType == "") {
        this.$alert("구분 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.scheduleType == "client" && this.user == "") {
        this.$alert("고객을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.adminType == "SUPER" && this.planner == "") {
        this.$alert("플래너를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.category == "") {
        this.$alert("스케줄 항목을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.date == "") {
        this.$alert("스케줄 날짜를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.title == "") {
        this.$alert("스케줄명을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      this.inactive = true;

      let data = {
        diaryId: this.scheduleId,
        scheduleType: this.scheduleType,
        title: this.title,
        content: this.content,
        category: this.category,
        followup: this.followup,
        date: this.date,
      };

      if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
        data.planner = this.planner;
      } else {
        data.planner = this.planner._id;
      }
      if (this.scheduleType == "client") {
        data.user = this.user._id;
      }

      if (this.editMode) {
        updateSchedule(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("스케쥴 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/schedulemanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createSchedule(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("스케쥴 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/schedulemanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            this.inactive = false;
          }
        });
      }
    },

    async getScheduleList() {
      let params = {
        user: this.user ? this.user._id : this.planner._id,
      };
      fetchScheduleList(params).then((res) => {
        if (res.data.status == 200) {
          this.scheduleList = res.data.data.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
